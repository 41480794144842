import React from 'react'
import { TextCaption, TextCaptionEmphasis } from '../composing/CSText'
import { SpacerV } from '../composing/Spacing'
import { Colors } from '../theme/constants'
import { Routes } from '../scopes/nav/constants'
import { useGoto } from '../scopes/nav/utils'
import { HydratedUserProfile } from '@commonstock/common/src/api/profile'
import { ProfileAvatar } from './Avatar'
import { CardItem } from './CardCarousel'

type Props = {
  user: Pick<HydratedUserProfile, 'name' | 'picture' | 'username'>
  badge?: React.ReactNode
  preventGotoProfile?: boolean
  button?: React.ReactNode
}

function InvestorListItem({ badge, user, preventGotoProfile, button }: Props) {
  const { name, picture, username } = user
  const onClick = useGoto(Routes.usernameProfile(username))

  return (
    <CardItem onClick={!preventGotoProfile ? onClick : undefined}>
      <ProfileAvatar avatar={picture} name={name} size={2} />
      <SpacerV rem={0.625} />
      {!!name && (
        <>
          <TextCaptionEmphasis block ellipsis>
            {name}
          </TextCaptionEmphasis>
          <SpacerV rem={0.125} />
        </>
      )}
      <TextCaption block ellipsis color={Colors.TextSecondary}>
        @{username}
      </TextCaption>
      {badge && (
        <>
          <SpacerV rem={0.625} />
          {badge}
        </>
      )}
      {button && (
        <>
          <SpacerV />
          {button}
        </>
      )}
    </CardItem>
  )
}

export default InvestorListItem
