import React from 'react'
import GlobalTrending from '../trending/GlobalTrending'
import { DiscoverMostBoughtAndSold, DiscoverMostTalkedAboutAssets } from '../discover/DiscoverAssets'
import { TrendingOrderType } from '@commonstock/common/src/api/recommendation'
import { DiscoverTopMemos } from '../discover/DiscoverMemos'
import { DiscoverInvestorsGainingPopularity, DiscoverInvestorsWritingBestContent } from '../discover/DiscoverInvestors'
import DashboardFooterLinks from './DashboardFooterLinks'
import { useTheme } from 'src/theme/ThemeContext'

function HomeDashboard() {
  const { isSinglePane } = useTheme()

  if (isSinglePane) return null
  return (
    <>
      <GlobalTrending />
      <DiscoverMostBoughtAndSold orderType={TrendingOrderType.Buy} />
      <DiscoverMostBoughtAndSold orderType={TrendingOrderType.Sell} />
      <DiscoverMostTalkedAboutAssets />
      <DiscoverTopMemos />
      <DiscoverInvestorsGainingPopularity />
      <DiscoverInvestorsWritingBestContent />
      <DashboardFooterLinks />
    </>
  )
}

export default HomeDashboard
