import React from 'react';
import { css } from '@linaria/core';
import { Periods } from '@commonstock/common/src/types';
import { useTopMovers } from '@commonstock/common/src/api/market';
import { TrendingOrderType, useGetInvestingTrending, useGetTrendingMentions, TrendingFilter } from '@commonstock/common/src/api/recommendation';
import { formatPercent, formatPercentSigned } from '@commonstock/common/src/utils/format';
import { Badge, GainBadge } from '../../composing/Badge';
import { DiscoverTodaysMarketWinnersSkeleton, DiscoverMostTalkedAboutSkeleton, DiscoverMostBoughtSoldSkeleton } from './Discover.skeleton';
import AssetListItem from '../../components/AssetListItem';
import CardCarousel from '../../components/CardCarousel';
import { SkeletonWrapper } from '../../components/SuspenseSkeleton';
import { useTheme } from 'src/theme/ThemeContext';
export function DiscoverTodaysMarketWinners() {
  const [topMovers, topMoversFail, topMoversPending] = useTopMovers();
  return <SkeletonWrapper pending={!!topMoversPending} skeleton={<DiscoverTodaysMarketWinnersSkeleton />} failed={topMovers && !topMovers.length || topMoversFail}>
      {topMovers && topMovers.length > 0 && <CardCarousel title={"Today's market winners"} showSliderButtons={topMovers.length > 3} height={6.75} topSlidesSpacer={1} bottomSlidesSpacer={1}>
          {topMovers.map((asset, index) => <AssetListItem key={index} name={asset.short_name} symbol={asset.ticker} type={asset.asset_type} badge={<GainBadge ellipsis value={formatPercentSigned(asset.change_percentage) || ''} />} />)}
        </CardCarousel>}
    </SkeletonWrapper>;
}
export function DiscoverMostTalkedAboutAssets() {
  const [mostMentioned, failed, mostMentionedPending] = useGetTrendingMentions({
    query: {
      limit: 10,
      period: Periods['1W']
    }
  });
  const assets = mostMentioned?.trends.map(t => t.trend);
  return <SkeletonWrapper pending={!!mostMentionedPending} skeleton={<DiscoverMostTalkedAboutSkeleton />} failed={failed && assets?.length === 0}>
      {assets && assets.length > 0 && <CardCarousel title={'Most talked about assets'} showSliderButtons={assets.length > 3} height={6.75} topSlidesSpacer={1.25} bottomSlidesSpacer={1.1875}>
          {assets.map((asset, index) => <AssetListItem key={index} name={asset.short_name || asset.asset_symbol} symbol={asset.asset_symbol} type={asset.asset_type} badge={<Badge className={longBadgeClass} ellipsis gradient value={`${formatPercent(asset.percent_of_total_mentions)}`} label="of mentions" />} />)}
        </CardCarousel>}
    </SkeletonWrapper>;
}
export function DiscoverMostBoughtAndSold({
  orderType
}: {
  orderType: TrendingOrderType;
}) {
  const {
    isMobile
  } = useTheme();
  const query = {
    limit: 10,
    order_side: orderType,
    period: Periods['1D'],
    type: TrendingFilter.Global
  };
  const [items,, pending] = useGetInvestingTrending({
    query
  });
  const assets = items?.trends.map(t => t.trend);
  const title = orderType === TrendingOrderType.Buy ? `Today's most bought${!isMobile ? ' on Commonstock' : ''}` : `Today's most sold${!isMobile ? ' on Commonstock' : ''}`;
  const action = orderType === TrendingOrderType.Buy ? 'Bought' : 'Sold';
  return <SkeletonWrapper pending={!!pending} skeleton={<DiscoverMostBoughtSoldSkeleton />} failed={assets && !assets.length}>
      {assets && assets.length > 0 && <CardCarousel title={title} showSliderButtons={assets.length > 3} height={6.75} topSlidesSpacer={1.5} bottomSlidesSpacer={1.1875}>
          {assets.map((asset, index) => <AssetListItem key={index} name={asset.asset_short_name} symbol={asset.asset_symbol} type={asset.asset_type} badge={<Badge ellipsis value={`${formatPercent(asset.percent)} ${action}`} />} />)}
        </CardCarousel>}
    </SkeletonWrapper>;
}
const longBadgeClass = "lrlo2v5";

require("../../../.linaria-cache/packages/oxcart/src/scopes/discover/DiscoverAssets.linaria.module.css");